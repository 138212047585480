<template>
    <div>
        <v-navigation-drawer v-model="drawer" app right v-if="isMobile">
            <v-container class="mt-6" >
                <v-row justify="center" align="center">
                    <img height="50" src="../assets/images/logo.png" class="" />
                </v-row>
            </v-container>
            <br />
            <v-divider />
            <br />
            <v-list>
                <v-list-item link :to="tab.link" v-for="(tab,index) in $store.state.tabs" :key="index" @click.prevent="selectTab(index, tab.link)" style="border-bottom: 1px solid #E0E0E0; color: #0F145B" :class="{ 'selected-item': selectedTab === tab.link }">
                    <v-list-item-title>
                        <h4>{{ tab.title }}</h4>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar flat class="white" app>
            <v-btn @click="drawer = !drawer" icon v-if="isMobile">
                <v-icon>fi fi-rr-bars-staggered</v-icon>
            </v-btn>
            <img  height="50" src="../assets/images/logo.png" class="mr-8" v-if="$route.path == '/home'"/>
            <v-spacer />
            <v-tabs centered class="hidden-sm-and-down">

                <v-tab router :to="tab.link" v-for="(tab,index) in $store.state.tabs" :key="index" >
                    <strong class="black--text" v-if="tab.children.length == 0" style="font-size: 16px; font-weight: 600">{{ tab.title }}</strong>
                    <v-menu open-on-hover offset-y rounded v-if="tab.children.length > 0">
                        <template v-slot:activator="{ on, attrs }">
                            <strong class="black--text" style="font-size: 16px; font-weight: 600" v-bind="attrs" v-on="on">{{ tab.title }}
                                <v-icon color="black" small>fi fi-rr-angle-small-down</v-icon>
                            </strong>
                        </template>
                        <v-list>
                            <v-list-item :href="item.link" v-for="(item, index) in tab.children" :key="index">
                                <v-list-item-title>
                                    <strong class="px-2 black--text" style="font-size: 16px; font-weight: 600">{{ item.title }}</strong>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-tab>

            </v-tabs>
            <v-spacer />
            <v-btn href="/reservation" link  color="primary" class="btn hidden-md-and-up" large  >
                احجزي  موعد
            </v-btn>
            <v-btn v-if="$route.path == '/home'" href="/reservation" link class="btn ml-12 ml-12 bubbly-button hidden-sm-and-down text-center" fab color="primary" x-large style="margin-top: 100px; font-weight: 600;font-size:14px;  " depressed>
                احجزي <br /> موعد
            </v-btn>
        </v-app-bar>
    </div>
</template>

<script>
export default {

    created() {
        this.onResize();
                window.addEventListener("resize", this.onResize, {
                    passive: true
                });

                if (this.isMobile) {
                    this.drawer = false;
                }
    },

    watch: {
        beforeDestroy() {
            if (typeof window !== "undefined") {
                window.removeEventListener("resize", this.onResize, {
                    passive: true
                });
            }
        }
    },

    methods: {
        onResize() {
            this.isMobile = window.innerWidth < 1000;
        },
    },

    data() {
        return {
            isMobile: false,
            drawer: false,
            
        }
    },
}
</script>

<style>
.custom-app-bar {
    background-color: rgba(255, 255, 255, 0.7) !important;
    backdrop-filter: blur(8px);
}

.bubbly-button {
    font-size: 1.2em;
    padding: 1em 1.3em;
    margin-top: 100px;
    margin-bottom: 40px;
    background-color: #F495BD;
    font-weight: bold;
    color: #fff;
    border-radius: 60px;
    border: none;
    cursor: pointer;
    position: relative;
    transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
    box-shadow: 0 2px 25px rgba(255, 0, 130, 0.5);
}

.bubbly-button:active {
    transform: scale(0.9);
    background-color: darken(#ff0081, 5%);
    box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
}

.bubbly-button:active:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -60px;
    margin-left: -60px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255);
    animation: bubble 0.5s ease-out forwards;
}

@keyframes bubble {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
        opacity: 0;
    }
}
</style>